<template>
  <b-card>
    <loading
      :active="isLoading"
      loader="bars"
      color="#7367f0"
      :is-full-page="fullPage"
    />
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <vue-good-table
      :columns="columns"
      :rows="rows"
      style-class="vgt-table striped"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPageDropdownEnabled: false,
        dropdownAllowAll: false,
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item :to="{ name: 'edit-user', params: { id: props.row.id } }">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteUser(props.row.id)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <span v-else-if="props.column.field == 'role'">
          <span v-if="props.row.role">{{ props.row.role }}</span>
          <span v-else>-</span>
        </span>
        <span v-else-if="props.column.field == 'departments'">
          <span v-if="props.row.departments">{{ props.row.departments }}</span>
          <span v-else>-</span>
        </span>
        <span v-else-if="props.column.field == 'churches'">
          <span v-if="props.row.churches">{{ props.row.churches }}</span>
          <span v-else>-</span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BFormGroup, BFormInput, BCard, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Loading from 'vue-loading-overlay'
import _ from 'lodash'
import CryptoJS from 'crypto-js'
import 'vue-loading-overlay/dist/vue-loading.css'
import { VueGoodTable } from 'vue-good-table'
import { isUserLoggedIn } from '@/auth/utils'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BCard,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    Loading,
  },
  data() {
    return {
      isLoading: true,
      fullPage: false,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'NIJ',
          field: 'nij',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Role',
          field: 'role',
        },
        {
          label: 'Ministries',
          field: 'departments',
        },
        {
          label: 'Divisions',
          field: 'divisions',
        },
        {
          label: 'Churches',
          field: 'churches',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  mounted() {
    // Set data
    const userData = JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('userData'), process.env.VUE_APP_COOKIE_SECRET).toString(CryptoJS.enc.Utf8))

    // Get the data
    this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/users`)
      .then(response => {
        this.isLoading = false
        
        // Filter based on role
        if (userData[0].role === 'PIC Servolution Regional') {
          this.rows = _.filter(response.data, item => item.role === 'PIC Servolution Regional' || item.role === 'PIC Servolution Local' || item.role === 'PIC Ministry' || item.role === 'PIC Baptism' || item.role === 'PIC Servolution Assessment' || item.role === null)
        } else if (userData[0].role === 'PIC Servolution Local') {
          this.rows = _.filter(response.data, item => item.role === 'PIC Servolution Local' || item.role === 'PIC Ministry' || item.role === 'PIC Baptism' || item.role === 'PIC Servolution Assessment' || item.role === null)
        } else if (userData[0].role === 'PIC Ministry') {
          this.rows = _.filter(response.data, item => item.role === 'PIC Servolution Assessment' || item.role === null)
        } else {
          this.rows = response.data
        }
    })
  },
  methods: {
    deleteUser(id) {
      // Check for cookies before submit
      // Get logged in status
      const isLoggedIn = isUserLoggedIn()
      // If not logged in, then redirect to login page
      if (!isLoggedIn) {
        return this.$router.replace('/login')
      }

      // Check if the user has access
      if (!this.$can('delete', 'User')) {
        this.$swal({
          icon: 'error',
          title: 'Forbidden!',
          text: 'You are not authorized to do this action.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      } else {
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            // Show loading
            this.isLoading = true

            // Delete user role
            this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/user/role/${id}`)
              .then(() => {
                // Delete user
                this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/user/${id}`)
                  .then(() => {
                    // Hide loading
                    this.isLoading = false
                    
                    this.$swal({
                      icon: 'success',
                      title: 'Deleted!',
                      text: 'The user has been deleted.',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })

                    const userIndex = this.rows.findIndex(user => user.id === id)
                    this.rows.splice(userIndex, 1)
                })
              })
          } else {
            // Hide loading
            this.isLoading = false
          }
        })
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
table.vgt-table {
  border: none;
  font-size: 1rem;
}
.vgt-table thead th {
  // border-bottom: 2px solid #ebe9f1;
  outline: none;
  background-color: #f3f2f7;
}
table.vgt-table td {
  vertical-align: middle !important;
}
.vgt-wrap__footer {
  border: none;
}
</style>